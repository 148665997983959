<template>
  <div class="page-works-add">
    <pro-form
      label-width="150px"
      label-suffix=":"
      :model="model"
      :fields="calculateFields"
      :rules="rules"
      :submitter="{
        submitButtonProps: {
          loading
        },
        submitText: $t('entries.add-submit-text'),
        resetText: $t('entries.add-cancel-text')
      }"
      @submit="submit"
      @reset="reset"
    ></pro-form>
  </div>
</template>

<script>
import { uid } from 'uid'
import Upload from '@/components/upload'
import { localStore } from '@/utils/store'
import { getFileName } from '@/utils/tools'
import * as ConstantRouter from '@/constant/router'

export default {
  data() {
    const t = this.$t.bind(this)
    return {
      loading: false,
      id: this.$route.params.id,
      jsList: [],
      jsLoading: false,
      trackList: [],
      trackLoading: false,
      model: {
        jsId: undefined,
        track: undefined,
        name: '',
        thumbnailFile: null,
        title: '',
        faceImgFile: null,
        detailImgFile: null,
        appendFile1File: null,
        appendFile2File: null,
        detailImgFiles: [],
        opusFix: '',
        innovate: '',
        marketFix: '',
        coreTechnology: '',
        productionProcess: '',
        futureProspects: '',
        modelFile: null,
        structureDocumentFile: null,
        opusVideoFile: null,
        otherFile: null,
        patentStatus: undefined,
        patentType: [],
        proveFile: [],
        onMarket: undefined,
        onMarketTime: undefined,
        price: ''
      },
      fields() {
        return [
          {
            render() {
              return (
                <div style="margin-left: -100px; color: #ffc800; font-size: 16px; font-weight: 600;">
                  ⌷ 基础信息
                </div>
              )
            }
          },
          {
            formItemProps: {
              style: 'width: 460px',
              label: t('entries.competition'),
              prop: 'jsId'
            },
            render: (h, prop, field, model) => {
              return (
                <el-select
                  style="width: 360px;"
                  v-model={model[prop]}
                  filterable
                  remote
                  reserve-keyword
                  placeholder={t('entries.placeholder-input')}
                  remoteMethod={this.fetchJsList}
                  onFocus={this.fetchJsListFocus}
                  loading={this.jsLoading}
                >
                  {this.jsList.map((item) => (
                    <el-option
                      key={item.value}
                      label={item.label}
                      value={item.value}
                    ></el-option>
                  ))}
                </el-select>
              )
            }
          },
          {
            formItemProps: {
              style: 'width: 460px',
              label: t('entries.categories'),
              prop: 'track'
            },
            render: (h, prop, field, model) => {
              return (
                <el-select
                  style="width: 360px;"
                  v-model={model[prop]}
                  filterable
                  remote
                  reserve-keyword
                  placeholder={t('entries.placeholder-input')}
                  remoteMethod={this.fetchTrackList}
                  onFocus={this.fetchTrackListFocus}
                  loading={this.trackLoading}
                >
                  {this.trackList.map((item) => (
                    <el-option
                      key={item.value}
                      label={item.label}
                      value={item.value}
                    ></el-option>
                  ))}
                </el-select>
              )
            }
          },
          {
            type: 'input',
            formItemProps: {
              label: t('entries.name'),
              prop: 'name'
            },
            style: {
              width: '360px'
            },
            attributes: {
              placeholder: t('entries.placeholder-input')
            }
          },
          {
            render() {
              return (
                <div style="margin-left: -100px; color: #ffc800; font-size: 16px; font-weight: 600;">
                  ⌷ 作品信息
                </div>
              )
            }
          },
          {
            type: 'input',
            formItemProps: {
              label: t('entries.thumbnail'),
              prop: 'thumbnailFile'
            },
            render(h, prop, field, model) {
              return (
                <Upload
                  v-model={model[prop]}
                  token={localStore.get('competitor-token')}
                ></Upload>
              )
            }
          },
          {
            type: 'textarea',
            formItemProps: {
              label: t('entries.title'),
              prop: 'title'
            },
            style: {
              width: '360px'
            },
            attributes: {
              maxlength: '100',
              showWordLimit: true,
              rows: 4,
              placeholder: t('entries.placeholder-input')
            }
          },
          {
            formItemProps: {
              label: t('entries.faceimg'),
              prop: 'faceImgFile'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                    beforeUpload={(file) => {
                      const isJPG = file.type === 'image/jpeg'
                      const isLt = file.size / 1024 / 1024 < 10
                      if (!isJPG) {
                        this.$message.error(t('entries.format-tip'))
                        // this.$message.error('上传头像图片只能是 JPG 格式!')
                        return false
                      }
                      if (!isLt) {
                        // this.$message.error('上传头像图片大小不能超过 3MB!')
                        this.$message.error(t('entries.format-tip'))
                        return false
                      }
                      return true
                    }}
                  ></Upload>
                  <p>{t('entries.faceimg-tip')}</p>
                </div>
              )
            }
          },
          {
            render() {
              return (
                <div style="margin-left: -100px; background: #ffd04b; padding: 20px 10px">
                  <p>{t('entries.tip')}</p>
                  <p>{t('entries.format-tip')}</p>
                </div>
              )
            }
          },
          {
            formItemProps: {
              label: t('entries.imgdetail'),
              prop: 'detailImgFile'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                    beforeUpload={(file) => {
                      const isJPG = file.type === 'image/jpeg'
                      const isLt2M = file.size / 1024 / 1024 < 3
                      if (!isJPG) {
                        this.$message.error(t('entries.format-tip'))
                        // this.$message.error('上传头像图片只能是 JPG 格式!')
                        return false
                      }
                      if (!isLt2M) {
                        // this.$message.error('上传头像图片大小不能超过 3MB!')
                        this.$message.error(t('entries.format-tip'))
                        return false
                      }
                      return true
                    }}
                  ></Upload>
                  <p>{t('entries.format-tip')}</p>
                </div>
              )
            }
          },
          {
            formItemProps: {
              label: t('entries.imgdetail'),
              prop: 'appendFile1File'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                    beforeUpload={(file) => {
                      const isJPG = file.type === 'image/jpeg'
                      const isLt2M = file.size / 1024 / 1024 < 3
                      if (!isJPG) {
                        this.$message.error(t('entries.format-tip'))
                        // this.$message.error('上传头像图片只能是 JPG 格式!')
                        return false
                      }
                      if (!isLt2M) {
                        // this.$message.error('上传头像图片大小不能超过 3MB!')
                        this.$message.error(t('entries.format-tip'))
                        return false
                      }
                      return true
                    }}
                  ></Upload>
                  <p>{t('entries.format-tip')}</p>
                </div>
              )
            }
          },
          {
            formItemProps: {
              label: t('entries.imgdetail'),
              prop: 'appendFile2File'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                    beforeUpload={(file) => {
                      const isJPG = file.type === 'image/jpeg'
                      const isLt2M = file.size / 1024 / 1024 < 3
                      if (!isJPG) {
                        this.$message.error(t('entries.format-tip'))
                        // this.$message.error('上传头像图片只能是 JPG 格式!')
                        return false
                      }
                      if (!isLt2M) {
                        // this.$message.error('上传头像图片大小不能超过 3MB!')
                        this.$message.error(t('entries.format-tip'))
                        return false
                      }
                      return true
                    }}
                  ></Upload>
                  <p>{t('entries.format-tip')}</p>
                </div>
              )
            }
          }
        ]
      },
      jxFields() {
        return [
          {
            render() {
              return (
                <div style="margin-left: -100px; color: #ffc800; font-size: 16px; font-weight: 600;">
                  ⌷ 基础信息
                </div>
              )
            }
          },
          {
            formItemProps: {
              style: 'width: 460px',
              label: t('entries.competition'),
              prop: 'jsId'
            },
            render: (h, prop, field, model) => {
              return (
                <el-select
                  style="width: 360px;"
                  v-model={model[prop]}
                  filterable
                  remote
                  reserve-keyword
                  placeholder={t('entries.placeholder-input')}
                  remoteMethod={this.fetchJsList}
                  onFocus={this.fetchJsListFocus}
                  loading={this.jsLoading}
                >
                  {this.jsList.map((item) => (
                    <el-option
                      key={item.value}
                      label={item.label}
                      value={item.value}
                    ></el-option>
                  ))}
                </el-select>
              )
            }
          },
          {
            formItemProps: {
              style: 'width: 460px',
              label: t('entries.categories'),
              prop: 'track'
            },
            render: (h, prop, field, model) => {
              return (
                <el-select
                  style="width: 360px;"
                  v-model={model[prop]}
                  filterable
                  remote
                  reserve-keyword
                  placeholder={t('entries.placeholder-input')}
                  remoteMethod={this.fetchTrackList}
                  onFocus={this.fetchTrackListFocus}
                  loading={this.trackLoading}
                >
                  {this.trackList.map((item) => (
                    <el-option
                      key={item.value}
                      label={item.label}
                      value={item.value}
                    ></el-option>
                  ))}
                </el-select>
              )
            }
          },
          {
            type: 'input',
            formItemProps: {
              label: t('entries.name'),
              prop: 'name'
            },
            style: {
              width: '360px'
            },
            attributes: {
              placeholder: t('entries.placeholder-input')
            }
          },
          {
            render() {
              return (
                <div style="margin-left: -100px; color: #ffc800; font-size: 16px; font-weight: 600;">
                  ⌷ 作品版面
                </div>
              )
            }
          },
          {
            type: 'input',
            formItemProps: {
              label: t('entries.thumbnail'),
              prop: 'thumbnailFile'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                  ></Upload>
                  <p>JPG格式, 正方形, 不超过2M</p>
                </div>
              )
            }
          },
          {
            type: 'input',
            formItemProps: {
              label: t('entries.effectpicture'),
              prop: 'faceImgFile'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                  ></Upload>{' '}
                  <p>JPG格式, 正方形(210 * 210mm), 无文字, 不超过2M</p>
                </div>
              )
            }
          },
          {
            type: 'input',
            formItemProps: {
              label: t('entries.chartlet'),
              prop: 'detailImgFiles'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                    limit={3}
                  ></Upload>
                  <p>
                    JPG格式, 版面格式A3, 竖版, 文件内字号不小于12号,
                    不得出现参赛者姓名或其他信息, 不超过2M，至多可上传3张
                  </p>
                </div>
              )
            }
          },
          {
            render() {
              return (
                <div style="margin-left: -100px; color: #ffc800; font-size: 16px; font-weight: 600;">
                  ⌷ 作品介绍
                </div>
              )
            }
          },
          {
            type: 'textarea',
            formItemProps: {
              label: '作品定位',
              prop: 'opusFix'
            },
            style: {
              width: '360px'
            },
            attributes: {
              maxlength: '50',
              showWordLimit: true,
              rows: 4,
              placeholder: t('entries.placeholder-input')
            }
          },
          {
            type: 'textarea',
            formItemProps: {
              label: '创新点',
              prop: 'innovate'
            },
            style: {
              width: '360px'
            },
            attributes: {
              maxlength: '300',
              showWordLimit: true,
              rows: 4,
              placeholder: t('entries.placeholder-input')
            }
          },
          {
            type: 'textarea',
            formItemProps: {
              label: '市场定位',
              prop: 'marketFix'
            },
            style: {
              width: '360px'
            },
            attributes: {
              maxlength: '300',
              showWordLimit: true,
              rows: 4,
              placeholder: t('entries.placeholder-input')
            }
          },
          {
            type: 'textarea',
            formItemProps: {
              label: '核心技术',
              prop: 'coreTechnology'
            },
            style: {
              width: '360px'
            },
            attributes: {
              maxlength: '300',
              showWordLimit: true,
              rows: 4,
              placeholder: t('entries.placeholder-input')
            }
          },
          {
            type: 'textarea',
            formItemProps: {
              label: '生产工艺',
              prop: 'productionProcess'
            },
            style: {
              width: '360px'
            },
            attributes: {
              maxlength: '300',
              showWordLimit: true,
              rows: 4,
              placeholder: t('entries.placeholder-input')
            }
          },
          {
            type: 'textarea',
            formItemProps: {
              label: '未来前景',
              prop: 'futureProspects'
            },
            style: {
              width: '360px'
            },
            attributes: {
              maxlength: '300',
              showWordLimit: true,
              rows: 4,
              placeholder: t('entries.placeholder-input')
            }
          },
          {
            render() {
              return (
                <div style="margin-left: -100px; color: #ffc800; font-size: 16px; font-weight: 600;">
                  ⌷ 技术文档
                </div>
              )
            }
          },
          {
            formItemProps: {
              label: '3D模型',
              prop: 'modelFile'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    type="zip"
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                  ></Upload>
                  <p>obj格式, 不超过20M, 允许上传zip压缩文件</p>
                </div>
              )
            }
          },
          {
            formItemProps: {
              label: '结构文档',
              prop: 'structureDocumentFile'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    type="zip"
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                  ></Upload>
                  <p>创新结构, 关键结构细节说明, pdf格式文件</p>
                </div>
              )
            }
          },
          {
            formItemProps: {
              label: '作品视频',
              prop: 'opusVideoFile'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    type="zip"
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                    beforeUpload={(file) => {
                      const isLt50M = file.size / 1024 / 1024 < 50
                      if (!isLt50M) {
                        this.$message.error(t('entries.video-format-tip'))
                        return false
                      }
                      return true
                    }}
                  ></Upload>{' '}
                  <p>作品视频、动画、MP4格式</p>
                </div>
              )
            }
          },
          {
            formItemProps: {
              label: '其他材料',
              prop: 'otherFile'
            },
            render(h, prop, field, model) {
              return (
                <div>
                  <Upload
                    type="zip"
                    v-model={model[prop]}
                    token={localStore.get('competitor-token')}
                  ></Upload>
                  <p>
                    包装设计、IP形象等材料, 参赛者可自行选择提供,
                    pdf/zip格式文件
                  </p>
                </div>
              )
            }
          },
          {
            render() {
              return (
                <div style="margin-left: -100px; color: #ffc800; font-size: 16px; font-weight: 600;">
                  ⌷ 知识产权情况
                </div>
              )
            }
          },
          {
            type: 'radio-group',
            formItemProps: {
              label: '专利情况',
              prop: 'patentStatus'
            },
            children: [
              {
                label: 1,
                text: '已申请'
              },
              {
                label: 2,
                text: '申请中'
              },
              {
                label: 3,
                text: '未申请'
              }
            ]
          },
          {
            render() {
              return (
                <div style="font-size: 14px; margin-top: -30px">
                  若获奖作品存在知识产权瑕疵或争议的, 组委会有权撤销获奖资格,
                  召回奖状、奖杯等荣誉、追回奖金。
                </div>
              )
            }
          },
          ...(() => {
            if (this.model.patentStatus === 1) {
              return [
                {
                  type: 'select-multiple',
                  // type: 'select',
                  style: {
                    width: '600px'
                  },
                  formItemProps: {
                    label: '专利类型',
                    prop: 'patentType'
                  },
                  children: [
                    {
                      value: 1,
                      label: '实用专利类型'
                    },
                    {
                      value: 2,
                      label: '外观设计类型'
                    },
                    {
                      value: 3,
                      label: '发明专利类型'
                    },
                    {
                      value: 4,
                      label: '软著/著作类型'
                    },
                    {
                      value: 5,
                      label: '商标类型'
                    }
                  ]
                }
              ]
            }
            return []
          })(),
          ...(() => {
            if ([1, 2].includes(this.model.patentStatus)) {
              return [
                {
                  formItemProps: {
                    label: '证明材料',
                    prop: 'proveFile'
                  },
                  render(h, prop, field, model) {
                    return (
                      <div>
                        <Upload
                          type="zip"
                          v-model={model[prop]}
                          token={localStore.get('competitor-token')}
                        ></Upload>
                        <p>
                          1-3张专利证明或专利申请受理书, jpg/pdf格式,
                          单张图小于2M
                        </p>
                      </div>
                    )
                  }
                }
              ]
            }
            return []
          })(),
          {
            render() {
              return (
                <div style="margin-left: -100px; color: #ffc800; font-size: 16px; font-weight: 600;">
                  ⌷ 运营情况
                </div>
              )
            }
          },
          {
            type: 'radio-group',
            formItemProps: {
              label: '上市情况',
              prop: 'onMarket'
            },
            children: [
              {
                label: 1,
                text: '已上市'
              },
              {
                label: 0,
                text: '未上市'
              }
            ]
          },
          ...(() => {
            if (this.model.onMarket === 1) {
              return [
                {
                  type: 'date',
                  formItemProps: {
                    style: 'width: 460px',
                    label: '上市时间',
                    prop: 'onMarketTime'
                  }
                },
                {
                  type: 'input',
                  formItemProps: {
                    style: 'width: 460px',
                    label: '上市售价',
                    prop: 'price'
                  }
                }
              ]
            }
            return []
          })(),
          ...(() => {
            if (this.model.onMarket === 0) {
              return [
                {
                  type: 'date',
                  formItemProps: {
                    style: 'width: 460px',
                    label: '预期上市时间',
                    prop: 'onMarketTime'
                  }
                },
                {
                  type: 'input',
                  formItemProps: {
                    style: 'width: 460px',
                    label: '预期上市售价',
                    prop: 'price'
                  }
                }
              ]
            }
            return []
          })()
        ]
      },
      rules: {
        jsId: [{ required: true, message: '请选择竞赛', trigger: 'change' }],
        track: [{ required: true, message: '请选择赛道', trigger: 'change' }],
        name: [
          { required: true, message: '请输入作品名称', trigger: 'change' }
        ],
        thumbnailFile: [
          { required: true, message: '请上传缩略图', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请输入作品简介', trigger: 'change' }
        ],
        faceImgFile: [
          { required: true, message: '请上传作品版面', trigger: 'change' }
        ],
        detailImgFiles: [
          { required: true, message: '请上传作品版面', trigger: 'change' }
        ],
        opusFix: [
          { required: true, message: '请输入作品定位', trigger: 'change' }
        ],
        innovate: [
          { required: true, message: '请输入创新点', trigger: 'change' }
        ],
        marketFix: [
          { required: true, message: '请输入市场定位', trigger: 'change' }
        ],
        coreTechnology: [
          { required: true, message: '请输入核心技术', trigger: 'change' }
        ],
        productionProcess: [
          { required: true, message: '请输入生产工艺', trigger: 'change' }
        ],
        futureProspects: [
          { required: true, message: '请输入未来前景', trigger: 'change' }
        ],
        patentStatus: [
          { required: true, message: '请选择专利情况', trigger: 'change' }
        ],
        patentType: [
          { required: true, message: '请选择专利类型', trigger: 'change' }
        ],
        proveFile: [
          { required: true, message: '请上传证明材料', trigger: 'change' }
        ],
        onMarket: [
          { required: true, message: '请选择上市情况', trigger: 'change' }
        ],
        onMarketTime: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
        price: [{ required: true, message: '请输入', trigger: 'change' }]
      }
    }
  },
  computed: {
    currentTrack() {
      const track = this.model.track
      const trackList = this.trackList
      if (!track) return null
      return trackList.find((item) => item.value === track)
    },
    calculateFields() {
      if (this.currentTrack?.option.type === 2) return this.jxFields()
      return this.fields()
    }
  },
  watch: {
    'model.jsId'(value) {
      if (value) {
        // this.track = undefined
        // this.trackList = []
      }
    }
  },
  mounted() {
    if (this.id) this.fetchDetail()
  },
  methods: {
    async fetchJsList(keyword) {
      this.jsLoading = true
      const [err, res] = await this.$serve.jsRecord.getPageList({
        data: {
          pageSize: 100,
          keyword
        }
      })
      this.jsLoading = false
      if (err) return
      this.jsList = res.records.map((item) => ({
        label: item.name,
        value: item.id
      }))
    },
    async fetchJsListFocus() {
      if (this.jsList.length < 2) {
        this.fetchJsList('')
      }
    },
    async fetchTrackList(keyword) {
      this.trackLoading = true
      const [err, res] = await this.$serve.jsTrack.getPageList({
        data: {
          pageSize: 100,
          keyword,
          jsId: this.model.jsId
        }
      })
      this.trackLoading = false
      if (err) return
      this.trackList = res.records.map((item) => ({
        label: item.name,
        value: item.id,
        option: item
      }))
    },
    async fetchTrackListFocus() {
      if (!this.trackList.length) {
        this.fetchTrackList('')
      }
    },
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsOpus.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.trackList = [
        {
          label: res.trackName,
          value: +res.track,
          option: {
            id: +res.track,
            name: res.trackName,
            type: res.type
          }
        }
      ]
      if (res.jsId) {
        this.jsList = [
          {
            label: res.jsName,
            value: res.jsId
          }
        ]
      }
      await this.$nextTick()
      const model = {
        name: res.name,
        jsId: res.jsId,
        track: +res.track,
        title: res.title,
        thumbnailFile: res.thumbnail
          ? {
              uid: uid(),
              status: 'success',
              url: res.thumbnail
            }
          : null,
        faceImgFile: res.faceImg
          ? {
              uid: uid(),
              status: 'success',
              url: res.faceImg
            }
          : null
      }
      if (res.type === 1) {
        Object.assign(model, {
          detailImgFile: res.detailImg
            ? {
                uid: uid(),
                status: 'success',
                url: res.detailImg
              }
            : null,
          appendFile1File: res.appendFile1
            ? {
                name: getFileName(res.appendFile1),
                uid: uid(),
                status: 'success',
                url: res.appendFile1
              }
            : null,
          appendFile2File: res.appendFile2
            ? {
                name: getFileName(res.appendFile2),
                uid: uid(),
                status: 'success',
                url: res.appendFile2
              }
            : null
        })
      }
      if (res.type === 2) {
        Object.assign(model, {
          detailImgFiles: (res.detailImg || '').split(',').map((item) => ({
            name: getFileName(item),
            uid: uid(),
            status: 'success',
            url: item
          })),
          opusFix: res.opusFix,
          innovate: res.innovate,
          marketFix: res.marketFix,
          coreTechnology: res.coreTechnology,
          productionProcess: res.productionProcess,
          futureProspects: res.futureProspects,
          modelFile: res.model
            ? {
                name: getFileName(res.model),
                uid: uid(),
                status: 'success',
                url: res.model
              }
            : null,
          structureDocumentFile: res.structureDocument
            ? {
                name: getFileName(res.structureDocument),
                uid: uid(),
                status: 'success',
                url: res.structureDocument
              }
            : null,
          opusVideoFile: res.opusVideo
            ? {
                name: getFileName(res.opusVideo),
                uid: uid(),
                status: 'success',
                url: res.opusVideo
              }
            : null,
          otherFile: res.otherFile
            ? {
                name: getFileName(res.otherFile),
                uid: uid(),
                status: 'success',
                url: res.otherFile
              }
            : null,
          patentStatus: res.patentStatus,
          patentType: res.patentType
            ? res.patentType.split(',').map((item) => +item)
            : [],
          proveFile: res.proveFile
            ? {
                name: getFileName(res.proveFile),
                uid: uid(),
                status: 'success',
                url: res.proveFile
              }
            : null,
          onMarket: +res.onMarket,
          onMarketTime: res.onMarketTime
            ? new Date(res.onMarketTime)
            : undefined,
          price: res.price
        })
      }

      this.model = model
    },
    async submit({
      thumbnailFile,
      faceImgFile,
      detailImgFile,
      appendFile1File,
      appendFile2File,
      detailImgFiles,
      modelFile,
      structureDocumentFile,
      opusVideoFile,
      patentType,
      otherFile,
      proveFile,
      onMarketTime,
      ...data
    }) {
      const params = {
        ...data
      }
      if (thumbnailFile) {
        params.thumbnail = thumbnailFile.response?.data || thumbnailFile?.url
      }
      if (faceImgFile) {
        params.faceImg = faceImgFile.response?.data || faceImgFile?.url
      }
      if (this.currentTrack?.option.type === 2) {
        if (detailImgFiles?.length) {
          params.detailImg = detailImgFiles
            .map((item) => item.response?.data || item?.url)
            .join(',')
        }
        if (modelFile) {
          params.model = modelFile.response?.data || modelFile?.url
        }
        if (structureDocumentFile) {
          params.structureDocument =
            structureDocumentFile.response?.data || structureDocumentFile?.url
        }
        if (opusVideoFile) {
          params.opusVideoF = opusVideoFile.response?.data || opusVideoFile?.url
        }
        if (otherFile) {
          params.otherFile = otherFile.response?.data || otherFile?.url
        }
        if (onMarketTime) {
          params.onMarketTime = +onMarketTime
        }
        if (proveFile) {
          params.proveFile = proveFile.response?.data || proveFile?.url
        }
        if (patentType?.length) {
          params.patentType = patentType.join(',')
        }
      } else {
        if (detailImgFile) {
          params.detailImg = detailImgFile.response?.data || detailImgFile?.url
        }
        if (appendFile1File) {
          params.appendFile1 =
            appendFile1File.response?.data || appendFile1File?.url
        }
        if (appendFile2File) {
          params.appendFile2 =
            appendFile2File.response?.data || appendFile2File?.url
        }
      }
      if (this.id) {
        this.update(params)
      } else {
        this.add(params)
      }
    },
    reset() {
      this.$router.push({
        name: ConstantRouter.COMPETITOR_WORKS
      })
    },
    async add(data) {
      this.loading = true
      const [err] = await this.$serve.jsOpus.add({
        data
      })
      this.loading = false
      if (err) return
      this.$message.success('作品添加成功')
      this.$router.push({
        name: ConstantRouter.COMPETITOR_WORKS
      })
    },
    async update(data) {
      this.loading = true
      const [err] = await this.$serve.jsOpus.update({
        data: {
          ...data,
          id: this.id
        }
      })
      this.loading = false
      if (err) return
      this.$message.success('作品修改成功')
      this.$router.push({
        name: ConstantRouter.COMPETITOR_WORKS
      })
    }
  }
}
</script>
