var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-works-add"},[_c('pro-form',{attrs:{"label-width":"150px","label-suffix":":","model":_vm.model,"fields":_vm.calculateFields,"rules":_vm.rules,"submitter":{
      submitButtonProps: {
        loading: _vm.loading
      },
      submitText: _vm.$t('entries.add-submit-text'),
      resetText: _vm.$t('entries.add-cancel-text')
    }},on:{"submit":_vm.submit,"reset":_vm.reset}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }